import React, { useEffect, useState, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useFormik } from "formik";
import { removeToken, setToken } from "../shared/Helpers";
import getAccessToken from "../app/services/getAccessToken";
import getUser from "../app/services/getUser";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../config";
import axios from "axios";
import updateUser from "../app/services/updateUser";
import Circle from "../assets/img/check_circle.svg";
import Back from "../assets/img/back_1.svg";
import Note from "../assets/img/note.svg";
import Mail from "../assets/img/mail.svg";
import sendMailToEmployer from "../app/services/sendMailToEmployer";
import sendMailToClient from "../app/services/sendMailToClient";
import updateLetter from "../app/services/updateLetter";
import Cycle from "../assets/img/cycle.svg";
import { Toast } from "primereact/toast";
import getSupervisorList from "../app/services/getSupervisorList";
import ErrorMessage from "../components/ErrorMessage";

const decryptEmail = (encryptedEmail) => {
  let result = "";
  for (let i = 0; i < encryptedEmail.length; i++) {
    let charCode = encryptedEmail.charCodeAt(i);
    let decryptedCharCode = charCode - 3;
    result += String.fromCharCode(decryptedCharCode);
  }
  return result;
};

const encryptEmail = (email) => {
  let result = "";
  for (let i = 0; i < email.length; i++) {
    let charCode = email.charCodeAt(i);
    let encryptedCharCode = charCode + 3;
    result += String.fromCharCode(encryptedCharCode);
  }
  return result;
};

const InternalReviewLetter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("customer");
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLetterSubmitted, setLetterSubmitted] = useState(false);
  const [letterSubmittedToGPT, setLetterSubmittedToGPT] = useState(false);
  const [isLetterSentToEmployee, setLetterSentToEmployee] = useState(false);
  const [dealID, setDealID] = useState("");
  const [encryptedDealID, setEncryptedDealID] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 5000;
  const [authToken, setAuthToken] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [commentToClients, setCommentToClient] = useState(false);
  const [buttonClicked, setButtonClicked] = useState("");
  const [supervisorList, setSupervisorList] = useState([]);
  const [selectedSupervisor, setSelectedSupervisor] = useState("");
  const [error, setError] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [pollingInterval, setPollingInterval] = useState(null);

  const handleError = (error, type, description) => {
    console.error(description, error);
    setError({ error, type, description });
  };

  useEffect(() => {
    const decryptAndFetchToken = async () => {
      if (paramValue) {
        try {
          const decryptedEmail = decryptEmail(paramValue);
          const response = await getAccessToken(decryptedEmail);
          const data = response.data;
          const userDetails = await getUser(data.token);
          const selectedSupervisor = userDetails.data.Supervisor ?? "";
          setSelectedSupervisor(selectedSupervisor);
          const supervisors = await getSupervisorList();
          const supervisorList =
            supervisors.data.data.attributes.SupervisorList;
          setSupervisorList(supervisorList);

          setAuthToken(data.token);
          setToken(data.token);
          setDealID(userDetails.data.dealID);
          setEncryptedDealID(userDetails.data.encryptedDealID);
          setUserDetails(userDetails.data);
          setLetterSubmitted(userDetails.data.wpLetterSubmittedToLawyers);
          setLetterSubmittedToGPT(userDetails.data.wpLetterSubmittedToChatGPT);
          setLetterSentToEmployee(userDetails.data.wpLetterSubmittedToEmployee);
          if (!userDetails.data.checkedWpLetter) {
            const intervalId = setInterval(async () => {
              try {
                const response = await getAccessToken(decryptedEmail);
                const data = response.data;
                const updatedUserDetails = await getUser(data.token);
                setUserDetails(updatedUserDetails.data);
                if (updatedUserDetails.data.checkedWpLetter) {
                  clearInterval(intervalId);
                  setIsLoading(false);
                }
              } catch (error) {
                console.error("Error polling for the letter:", error);
              }
            }, 5000);

            setPollingInterval(intervalId);
            return () => clearInterval(intervalId);
          } else {
            setIsLoading(false);
          }
        } catch (e) {
          navigate("/login");
        }
      } else {
        removeToken();
        navigate("/login");
      }
    };

    decryptAndFetchToken();
  }, [navigate, paramValue]);

  const cancel = () => {
    setCommentToClient(false);
  };

  const enableCommentToClient = () => {
    setCommentToClient(true);
  };

  const formik = useFormik({
    initialValues: {
      letterFeedback: "",
      checkedWpLetter: "",
      letterSupervisor: "",
      commentToClient: "",
    },
    validate: (values) => {
      const errors = {};
      if (buttonClicked === "sendToGpt" && !values.letterFeedback) {
        errors.letterFeedback = "Required";
      }
      if (
        buttonClicked === "sendToEmployer" &&
        !values.letterSupervisor &&
        !selectedSupervisor
      ) {
        errors.letterSupervisor = "Please select a supervisor";
      }
      return errors;
    },
    onSubmit: async (values) => {
      if (buttonClicked === "sendToGpt") {
        if (dealID) {
          setIsSubmitting(true);
          try {
            const formData = {
              userId: userDetails.id,
              name: userDetails.name,
              email: userDetails.email,
              dealID: userDetails.dealID,
              checkedWpLetter: values.checkedWpLetter,
              internalComments: values.letterFeedback,
              paragraph: `Comment:\n\n${values.letterFeedback}\n\nLetter:\n\n${values.checkedWpLetter}`,
            };
            await updateLetter(formData);
            window.location.reload();
          } catch (error) {
            handleError(error, 'submit', "Error generating and showing the letter (sendToGpt)");
          } finally {
            setIsSubmitting(false);
          }
        }
      } else if (buttonClicked === "saveDraft") {
        setIsSubmitting(true);
        try {
          const updateField = {
            checkedWpLetter: values.checkedWpLetter,
            internalComments: values.letterFeedback,
          };
          await updateUser(authToken, updateField);
        } catch (error) {
          handleError(error, 'submit', "Error generating and showing the letter (saveDraft)");
        } finally {
          setIsSubmitting(false);
        }
      } else if (buttonClicked === "approve") {
        if (dealID) {
          setIsSubmitting(true);
          try {
            const encryptedUserEmail = encryptEmail(userDetails.email);
            const baseUrl = window.location.origin;
            const payload = {
              dealID: dealID,
              comment: `Letter for review by trainee comment: ${values.letterFeedback}`,
              wpLetterUrl: `${baseUrl}/internal-review?customer=${encryptedUserEmail}`,
              stage: "lawyer",
              ...(values.letterSupervisor && {
                letterSupervisor: values.letterSupervisor,
              }),
            };
            await axios.post(`${config.ROTA_URL}submit-letter`, payload);
            const updateField = {
              checkedWpLetter: values.checkedWpLetter,
              ...(values.letterFeedback && {
                internalComments: values.letterFeedback,
              }),
              wpLetterSubmittedToLawyers: true,
              ...(values.letterSupervisor && {
                Supervisor: values.letterSupervisor,
              }),
            };
            await updateUser(authToken, updateField);
            // await sendMailToClient(
            //   "contact@grapple.uk",
            //   userDetails.name,
            //   values.checkedWpLetter,
            //   "wp-letter",
            //   "d-82e358093802405181978bafe66ebe56",
            //   dealID
            // );
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail:
                "Thanks for Approving this letter in the first instance. You can now continue to send the letter to employer, or to get supervision, as appropriate.",
              life: 5000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          } catch (error) {
            handleError(error, 'submit', "Error generating and showing the letter (approve)");
          } finally {
            setIsSubmitting(false);
          }
        }
      } else if (buttonClicked === "sendToEmployer") {
        setIsSubmitting(true);
        const timestamp = new Date();
        const baseUrl = window.location.origin;
        const encryptedUserEmail = encryptEmail(userDetails.email);
        if (dealID) {
          try {
            const updateField = {
              checkedWpLetter: values.checkedWpLetter,
              internalComments: values.letterFeedback,
              premium: true,
              wpLetterSubmittedToEmployee: true,
              wpLetterSetOn: timestamp,
              ...(values.letterSupervisor && {
                Supervisor: values.letterSupervisor,
              }),
            };
            await updateUser(authToken, updateField);
            await sendMailToEmployer(
              userDetails.survey.contact_email,
              userDetails.survey.contact_name,
              values.checkedWpLetter,
              "wp-letter",
              "d-09070d7d2163469c99f8873d5faedcf8",
              dealID,
              userDetails.name,
              encryptedDealID
            );
            await sendMailToClient(
              userDetails.email,
              userDetails.name,
              values.checkedWpLetter,
              "wp-letter",
              "d-fbaffe56d0334b0d98c7322cbb8285a7",
              dealID
            );
            const payload = {
              dealID: dealID,
              comment: `Final comment before sending it to employer: ${values.letterFeedback}`,
              wpLetterUrl: `${baseUrl}/internal-review?customer=${encryptedUserEmail}`,
              stage: "employee",
              ...(values.letterSupervisor && {
                letterSupervisor: values.letterSupervisor,
              }),
            };
            await axios.post(`${config.ROTA_URL}submit-letter`, payload);
            navigate("/request-submitted");
          } catch (e) {
            handleError(error, 'submit', "Error generating and showing the letter (sendToEmployer)");
          } finally {
            setIsSubmitting(false);
          }
        }
      } else if (buttonClicked === "sendToClient") {
        setIsSubmitting(true);
        const baseUrl = window.location.origin;
        const encryptedUserEmail = encryptEmail(userDetails.email);
        if (dealID) {
          try {
            const updateField = {
              comments: [
                ...(userDetails.comments || []),
                {
                  comment: values.commentToClient,
                  timestamp: new Date().toISOString(),
                  type: "Internal",
                },
              ],
              SentBackToClient: true,
              ClientAnswered: false,
            };
            await updateUser(authToken, updateField);
            const payload = {
              dealID: dealID,
              commentToClient: `${
                Array.isArray(userDetails.comments)
                  ? userDetails.comments
                      .map((comment) => `${comment.type}: ${comment.comment}`)
                      .join("\n")
                  : userDetails.comments || ""
              }\nInternal: ${values.commentToClient}`,
              wpLetterUrl: `${baseUrl}/internal-review?customer=${encryptedUserEmail}`,
              stage: "client",
            };

            await axios.post(`${config.ROTA_URL}submit-letter`, payload);
            navigate("/submitted-successfully");
          } catch (e) {
            handleError(error, 'submit', "Error generating and showing the letter (sendToClient)");
          } finally {
            setIsSubmitting(false);
          }
        }
      }
    },
  });
  useEffect(() => {
    if (
      userDetails.internalComments !== undefined ||
      userDetails.userComments !== undefined
    ) {
      formik.setValues({
        letterFeedback:
          userDetails.internalComments || userDetails.userComments || "",
      });
    } else {
      formik.setValues({
        letterFeedback: "", // Set it to empty if both are undefined
      });
    }

    if (
      userDetails.checkedWpLetter &&
      !userDetails.wpLetterSubmittedToChatGPT
    ) {
      let formattedContent = userDetails.checkedWpLetter.replace(
        /^#+\s*(.*)$/gm,
        "<strong>$1</strong>"
      );

      // Replace markdown bold syntax (e.g., **bold**) with <strong>.
      formattedContent = formattedContent.replace(
        /\*\*(.*?)\*\*/g,
        "<strong>$1</strong>"
      );

      // Replace newlines with <br /> where necessary, but avoid adding <br /> next to <p>, </p>, or where there are duplicate <br />s.
      formattedContent = formattedContent.replace(
        /([^\n])\n(?!<br>)(?<!<\/p>)(?!<p>)/g,
        "$1<br />"
      );

      // Remove any spaces directly inside <p> tags.
      formattedContent = formattedContent
        .replace(/<p>\s+/g, "<p>") // Remove space after opening <p> tag
        .replace(/\s+<\/p>/g, "</p>"); // Remove space before closing </p> tag

      // Remove empty <p></p> tags (even if there are spaces or newlines in between).
      formattedContent = formattedContent.replace(/<p>\s*<\/p>/g, "");

      // Remove <p><br></p> tags (including those with spaces or variations in <br> tag formatting).
      formattedContent = formattedContent.replace(
        /<p>\s*<br\s*\/?>\s*<\/p>/g,
        ""
      );

      // Set the field value in Formik to update the state.
      formik.setFieldValue("checkedWpLetter", formattedContent || "<p></p>");
    } else {
      formik.setFieldValue(
        "checkedWpLetter",
        userDetails.checkedWpLetter || "<p></p>"
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const createMarkup = (comment) => {
    return comment.replace(/\n/g, "<br />");
  };

  return (
    <div className="letter client-review internal">
      <div className="letter-header-message">
        {isLetterSubmitted && (
          <div className="letter-header">
            <p>
              Review and Edit Client Comments and Draft Without Prejudice Letter
            </p>
            Ensure that client comments have been addressed and make any
            necessary edits to the WP letter before approval or sending to
            ChatGPT
          </div>
        )}
        {!isLetterSubmitted && (
          <div className="letter-header">
            <p>
              Review and Edit Client Comments and Draft Without Prejudice Letter
            </p>
            Ensure that client comments have been addressed and make any
            necessary edits to the WP letter before approval or sending to
            ChatGPT
          </div>
        )}
      </div>
      <div className="letter-content">
        <form onSubmit={formik.handleSubmit}>
          <div className="comment">
            <label htmlFor="letterFeedback">Client Comments</label>
            {!isLetterSentToEmployee && (
              <button
                type="submit"
                name="submit_button_1"
                onClick={() => setButtonClicked("sendToGpt")}
                disabled={isSubmitting}
              >
                <img src={Note} width="auto" height="auto" alt="circle" />
                Send to ChatGPT
              </button>
            )}
            <textarea
              id="letterFeedback"
              name="letterFeedback"
              maxLength={maxCharLimit}
              onChange={(e) => {
                formik.handleChange(e);
                setCharCount(e.target.value.length);
              }}
              value={formik.values.letterFeedback}
            />
            <span>
              {charCount}/{maxCharLimit}
            </span>
          </div>
          <div className="letter-header-content">
            <h5>Without Prejudice Draft Letter</h5>
            {isLetterSubmitted && <p>for review by Supervisor</p>}
            {!isLetterSubmitted && <p>for review by Trainee</p>}
            {/* Chat UI for Comments */}
            {userDetails.comments && userDetails.comments.length > 0 && (
              <div className="comments-chat">
                <h6>Comments History</h6>
                <div className="comments-list">
                  {userDetails.comments.map((comment, index) => (
                    <div
                      key={index}
                      className={`comment-item ${
                        comment.type === "Internal"
                          ? "left-align"
                          : "right-align"
                      }`}
                    >
                      <p className="comment-type">
                        {comment.type === "Internal" ? "Lawyer" : comment.type}{" "}
                        <span className="comment-timestamp">
                          {new Date(comment.timestamp).toLocaleString()}
                        </span>
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: createMarkup(comment.comment),
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="letter-content-header">
              <div className="letter-actions">
                <button type="button" onClick={enableCommentToClient}>
                  <img src={Back} width="auto" height="auto" alt="circle" />
                  Back to Client
                </button>
                <button
                  type="submit"
                  name="submit_button_2"
                  onClick={() => setButtonClicked("saveDraft")}
                >
                  Save Draft
                </button>
              </div>
              <div className="letter-actions">
                <div>
                  <select
                    name="letterSupervisor"
                    id="letterSupervisor"
                    value={formik.values.letterSupervisor || selectedSupervisor} // Use selectedSupervisor as the default
                    onChange={formik.handleChange} // Handle Formik change
                  >
                    <option value="" label="Select Supervisor" />
                    {supervisorList &&
                      supervisorList?.supervisors?.map((supervisor, index) => (
                        <option key={index} value={supervisor}>
                          {supervisor}
                        </option>
                      ))}
                  </select>
                  {formik.errors.letterSupervisor && (
                    <div className="error-message">
                      {formik.errors.letterSupervisor}
                    </div>
                  )}
                </div>

                {!isLetterSubmitted && (
                  <button
                    type="submit"
                    name="submit_button_3"
                    className="accept"
                    onClick={() => setButtonClicked("approve")}
                  >
                    <img src={Circle} width="auto" height="auto" alt="circle" />
                    Approve
                  </button>
                )}
                {isLetterSubmitted && !isLetterSentToEmployee && (
                  <button
                    type="submit"
                    name="submit_button_4"
                    className="accept"
                    onClick={() => setButtonClicked("sendToEmployer")}
                    disabled={
                      !formik.values.letterSupervisor && !selectedSupervisor
                    }
                  >
                    <img src={Circle} width="auto" height="auto" alt="circle" />
                    Send letter to employer
                  </button>
                )}
              </div>
            </div>
          </div>
          {commentToClients && (
            <>
              <div className="comment toClient">
                <label htmlFor="commentToClient">
                  Input your comments to be sent to client in the covering email
                </label>
                <textarea
                  id="commentToClient"
                  name="commentToClient"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setCharCount(e.target.value.length);
                  }}
                  value={formik.values.commentToClient}
                />
                <div className="action">
                  <div className="cancel" onClick={cancel}>
                    Cancel
                  </div>
                  <button
                    type="submit"
                    name="submit_button_5"
                    className="sendToClient"
                    onClick={() => setButtonClicked("sendToClient")}
                  >
                    <img src={Mail} width="auto" height="auto" alt="circle" />
                    Send to Client
                  </button>
                </div>
              </div>
            </>
          )}
          <div className="letter-content-container">
            <div id="content" className="letter-content logged-in">
              <ReactQuill
                theme="snow"
                value={formik.values.checkedWpLetter}
                onChange={(content) =>
                  formik.setFieldValue("checkedWpLetter", content)
                }
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", false, "large", "huge"] }],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                    ["clean"],
                  ],
                }}
              />
            </div>
          </div>
        </form>
      </div>
      <ErrorMessage {...error} componentName="InternalReviewLetter" />
      {isSubmitting && (
        <div className="submit-loader">
          <div>
            <img src={Cycle} width="auto" height="auto" alt="cycle" />
            <p>Submitting</p>
          </div>
        </div>
      )}
      <Toast ref={toast} />
    </div>
  );
};

export default InternalReviewLetter;
