import React, { useState, useEffect, useMemo } from "react";
import showdown from "showdown";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import DataInputsFlow from "./survey/DataInputs.json";
import { useLocation, useNavigate } from "react-router-dom";
import "survey-core/modern.css";
import {
  createImageElement,
  filterArrayByIssueType,
  addThreeMonthsToDate,
} from "../shared/Helpers";
import getIssues from "../app/services/getIssues";
import Info from "../assets/img/Info.svg";
import Close from "../assets/img/Close.svg";
import getStatements from "../app/services/getStatements";
import getTemplateLetters from "../app/services/getTemplateLetters";
import grappleAi from "../app/services/grappleAi";
import getParagraphs from "../app/services/getParagraphs";
import getPrompts from "../app/services/getPrompts";
import getWitnessStatementLetter from "../app/services/getWitnessLetter";
import getAdviceNotes from "../app/services/getAdviceNotes";
import getWpLetter from "../app/services/getWpLetter";
import getAdviceNote from "../app/services/getAdviceNote";
import getGrievanceLetter from "../app/services/getGrievanceLetter";
import getEt1Letter from "../app/services/getEt1Letter";
import getDsarLetter from "../app/services/getDsarLetter";
import getResponseLetter from "../app/services/getResponseLetter";
import getUser from "../app/services/getUser";
import updateUser from "../app/services/updateUser";
import { getToken, removeToken, getLoginType } from "./../shared/Helpers";
import ErrorMessage from "../components/ErrorMessage";

const employmentIssues = [
  { value: "B", label: "Bullying" },
  { value: "RR", label: "Redundancy" },
  { value: "W", label: "Whistleblowing" },
  { value: "S", label: "Sickness" },
  { value: "Sn", label: "Suspension" },
  { value: "Dy", label: "Disciplinary" },
  { value: "H", label: "Unsafe workplace" },
  { value: "P", label: "Performance" },
  { value: "TWE", label: "Toxic work environment" },
  { value: "EW", label: "Excessive workload" },
  { value: "M", label: "Money owed" },
  { value: "F", label: "No contract" },
  { value: "OBT", label: "Other" },
];

const discriminationIssues = [
  {
    value: "DR",
    label: "Race or ethnicity",
  },
  {
    value: "DS",
    label: "Sex/gender",
  },
  {
    value: "DP",
    label: "Pregnancy",
  },
  {
    value: "DM",
    label: "Maternity",
  },
  {
    value: "DD",
    label: "Physical or Mental health condition",
  },
  {
    value: "DA",
    label: "Age",
  },
  {
    value: "DRn",
    label: "Religious belief",
  },
  {
    value: "DPl",
    label: "Philosophical belief",
  },
  {
    value: "DSy",
    label: "Sexual orientation",
  },
  {
    value: "DG",
    label: "Gender reassignment",
  },
  {
    value: "DMe",
    label: "Marital or civil partnership status",
  },
  {
    value: "ORT",
    label: "Other",
  },
];

const statusList = [
  { value: "E", label: "Still employed" },
  { value: "Rd", label: "Resigned" },
  { value: "T", label: "Dismissed" },
];

function DataInputs() {
  const converter = new showdown.Converter();
  const location = useLocation();
  const [queryParamValue, setQueryParamValue] = useState("");
  const [issues, setIssues] = useState({
    other: [],
    employment: [],
    dismissal: [],
    redundancy: [],
  });
  const [modalContent, setModalContent] = useState("");
  const [statementList, setStatementList] = useState([]);
  const [templateLettersList, setTemplateLettersList] = useState([]);
  const [adviceNote, setAdviceNote] = useState([]);
  const [letterTitle, setLetterTitle] = useState("");
  const [paraPrompt, setParaPrompt] = useState("");
  const [letterInfo, setLetterInfo] = useState({});
  const [isUserLoggedin, setIsUserLoggedin] = useState(false);
  const [userData, setUserData] = useState({});
  const [error, setError] = useState(null);
  const onTextMarkdown = (_survey, options) => {
    let str = converter.makeHtml(options.text);
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    options.html = str;
  };
  const survey = useMemo(() => new Model(DataInputsFlow), []);
  const titleDivider = "||";
  const navigate = useNavigate();
  const authToken = getToken();
  survey.completeText = "Generate letter";
  const loginType = getLoginType();

  const handleError = (error, type, description) => {
    console.error(description, error);
    setError({ error, type, description });
  };

  useEffect(() => {
    const fetchLoggedInUser = async (token) => {
      try {
        const response = await getUser(token);
        const data = response.data;
        setUserData(data);
        survey.data = data.survey;
        survey.setValue("selected_letter", null);
        const queryParams = new URLSearchParams(location.search);
        if (queryParams) {
          const paramValue = queryParams.get("letter");
          const lastPage = queryParams.get("lastPage");
          survey.setValue("selected_letter", paramValue);
          if (paramValue === "response-letter") {
            survey.currentPage = "respond-to-employer";
          }
          if (lastPage) {
            survey.currentPage = lastPage;
          }
          setQueryParamValue(paramValue);
        }
        if (loginType === "lawyer") {
          survey.setValue("user_type", "lawyer");
        } else {
          survey.setValue("user_type", "client");
        }
        setIsUserLoggedin(true);
      } catch (error) {
        removeToken();
        setIsUserLoggedin(false);
        console.error(error);
      }
    };
    if (authToken) {
      fetchLoggedInUser(authToken);
    } else {
      setIsUserLoggedin(false);
      const queryParams = new URLSearchParams(location.search);
      if (queryParams) {
        const paramValue = queryParams.get("letter");
        const lastPage = queryParams.get("lastPage");
        survey.setValue("selected_letter", paramValue);
        if (paramValue === "response-letter") {
          survey.currentPage = "respond-to-employer";
        }
        if (lastPage) {
          survey.currentPage = lastPage;
        }
        setQueryParamValue(paramValue);
      }
    }
  }, [authToken, location.search, loginType, survey]);

  useEffect(() => {
    const fetchIssues = async () => {
      try {
        const response = await getIssues();
        const data = response.data.data;
        const issueTypes = [1, 2, 3, 4];
        const newIssues = issueTypes.reduce((acc, type) => {
          acc[type] = filterArrayByIssueType(data, type).map((issue) => ({
            value: issue.attributes.TopicId,
            text: issue.attributes.Name,
          }));
          return acc;
        }, {});

        const {
          data: {
            data: {
              attributes: { ParaPrompt },
            },
          },
        } = await getPrompts();
        setParaPrompt(ParaPrompt);

        setIssues({
          employment: newIssues[1],
          other: [{ value: "none", text: "None" }, ...newIssues[2]],
          dismissal: newIssues[3],
          redundancy: newIssues[4],
        });
      } catch (error) {
        handleError(error, 'load', "Error fetching issues");
      }
    };

    fetchIssues();
  }, []);

  useEffect(() => {
    let letterText = "";
    const fetchLetter = async (letterType) => {
      switch (letterType) {
        case "advice-note":
          letterText = "Advice note";
          return { letter: await getAdviceNote(), text: letterText };
        case "wp-letter":
          letterText = "Without Prejudice Letter";
          return { letter: await getWpLetter(), text: letterText };
        case "grievance-letter":
          letterText = "Grievance Letter";
          return { letter: await getGrievanceLetter(), text: letterText };
        case "et1-letter":
          letterText = "ET1 Letter";
          return { letter: await getEt1Letter(), text: letterText };
        case "witness-statement-letter":
          letterText = "Witness Statement";
          return {
            letter: await getWitnessStatementLetter(),
            text: letterText,
          };
        case "dsar-letter":
          letterText = "DSAR Letter";
          return { letter: await getDsarLetter(), text: letterText };
        case "response-letter":
          letterText = "Response to employer";
          return { letter: await getResponseLetter(), text: letterText };
        default:
          return Promise.reject("Invalid letter type");
      }
    };

    const fetchData = async () => {
      try {
        if (queryParamValue) {
          const { letter, text } = await fetchLetter(queryParamValue);
          if (letter && text) {
            const letterInfo = letter.data.data.attributes;
            setLetterInfo(letterInfo);
            setLetterTitle(text);
          }
        }
      } catch (error) {
        handleError(error, 'load', "Error fetching letter");
      }
    };

    fetchData();
  }, [queryParamValue]);

  survey.getQuestionByName("other_issues").choices = [
    issues.other[0], // Keep the first item
    ...issues.other.slice(2), // Skip the second item and take the rest
  ];

  useEffect(() => {
    const handleClickOutsideTooltip = (e) => {
      const tooltip = document.getElementById("tooltip");
      if (tooltip.style.display === "block" && !tooltip.contains(e.target)) {
        tooltip.style.display = "none";
      }
    };
    document.addEventListener("click", handleClickOutsideTooltip);
    return () => {
      document.removeEventListener("click", handleClickOutsideTooltip);
    };
  }, []);

  const onAfterRenderQuestion = (__survey, options) => {
    const employmentIssue = survey.getQuestionByName("employment_issues");
    const employmentStatus = survey.getValue("employment_status");
    if (employmentIssue && employmentStatus === "T") {
      survey.getQuestionByName("employment_issues").choices = issues.employment;
    } else {
      survey.getQuestionByName("employment_issues").choices = issues.employment;
    }

    options.htmlElement.querySelectorAll(".sv-string-viewer").forEach((el) => {
      const text = el.innerText;
      if (text.includes(titleDivider)) {
        const [textBeforeDivider, titleText] = text?.split(titleDivider);
        el.title = titleText;
        el.innerText = textBeforeDivider;

        const img = createImageElement(Info, "Tooltip icon", (e) => {
          e.stopPropagation();
          setModalContent(titleText);
          const rect = img.getBoundingClientRect();
          const tooltip = document.getElementById("tooltip");
          tooltip.style.display = "block";
          tooltip.style.left = `${rect.right + 10}px`;
          tooltip.style.top = `${rect.top}px`;
        });

        el.insertAdjacentElement("afterend", img);
      }
    });
  };

  const handleCustomButtonClick = () => {
    navigate(-1);
  };

  const onAfterRenderPage = (__survey, options) => {
    let pageName = survey.currentPage.name;
    let param = queryParamValue || "";
    document.body.className = `${pageName} ${param}`.trim();

    if (
      survey.currentPage.name === "time-limit-calculator" ||
      survey.currentPage.name === "respond-to-employer"
    ) {
      const header = document.querySelector(
        `.time-limit-calculator .sv-action-bar.sv-footer.sv-body__footer, .respond-to-employer .sv-action-bar.sv-footer.sv-body__footer`
      );

      if (header && !header.querySelector(".back-btn")) {
        const btn = document.createElement("button");
        btn.type = "button";
        btn.id = "back-btn";
        btn.className = "btn back-btn";
        btn.innerHTML = "<span>Back</span>";

        btn.onclick = function () {
          handleCustomButtonClick();
        };

        header.appendChild(btn);
      }
    }
  };

  const onValueChanged = (survey, options) => {
    if (options.name === "date" && options.value) {
      const date = options.value;
      const dates = addThreeMonthsToDate(date);
      const mnth = `0${dates.getMonth() + 1}`.slice(-2);
      const day = `0${dates.getDate()}`.slice(-2);
      if (date) {
        survey.setValue(
          "time_limit",
          [day, mnth, dates.getFullYear()].join("-")
        );
      }
    }
  };

  const onValidateQuestion = (sender, options) => {
    if (options.name === "time_limit" && options.value) {
      survey.ignoreValidation = true;
      const parts = options?.value?.split("-");
      const inputDate = new Date(parts[2], parts[1] - 1, parts[0]);
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      if (inputDate < currentDate) {
        options.error =
          "It looks like your claim might be out of time based on these dates. Complete the Monaco Solicitors AI Advice Note for more information.";
      }
    }
  };

  const onCurrentPageChanged = async (sender, options) => {
    const userInput = sender.data;
    const nextButton = document.querySelector('input[value="Continue"]');
    const howLong = userInput?.how_long || "";
    const dismissalIssues = [];
    const redundancyIssues = [];
    const employmentIssues = userInput.employment_issues || [];
    const otherIssues =
      userInput.other_issues && userInput.other_issues.includes("none")
        ? []
        : userInput.other_issues
        ? [...userInput.other_issues, "D"]
        : [];

    const employmentStatus = userInput?.employment_status || "";
    const combinedIssues = [
      ...dismissalIssues,
      ...redundancyIssues,
      ...employmentIssues,
      ...otherIssues,
    ];
    const combinedTopics = [employmentStatus, howLong, ...combinedIssues];
    sender.ignoreValidation =
      sender.currentPage.name === "time-limit-calculator";
    if (isUserLoggedin && authToken) {
      try {
        await updateUser(authToken, { survey: userInput });
      } catch (e) {
        console.log(e);
      }
    }
    if (survey.currentPage.name === "narrative" && options.isNextPage) {
      nextButton.disabled = true;
      try {
        const response = await getAdviceNotes(combinedTopics);
        const adviceNoteList = response?.data;
        const adviceNoteParagraph = adviceNoteList
          .map((item) => item.title)
          .join("\n\n");
        const { data } = await getStatements(
          employmentStatus,
          combinedIssues,
          combinedTopics
        );
        const statementList = data.map((item) => ({
          id: item.id,
          statement: item.title,
        }));
        const hasE = combinedTopics.includes("E");
        const has2y = combinedTopics.includes("2y");

        if (queryParamValue === "wp-letter") {
          if (!hasE && !has2y) {
            letterInfo.HeaderText = letterInfo.HeaderText1;
            letterInfo.FooterText = letterInfo.FooterText1;
          } else if (hasE && !has2y) {
            letterInfo.HeaderText = letterInfo.HeaderText2;
            letterInfo.FooterText = letterInfo.FooterText2;
          } else if (hasE && has2y) {
            letterInfo.HeaderText = letterInfo.HeaderText3;
            letterInfo.FooterText = letterInfo.FooterText3;
          } else if (!hasE && has2y) {
            letterInfo.HeaderText = letterInfo.HeaderText4;
            letterInfo.FooterText = letterInfo.FooterText4;
          }
          const { data } = await getTemplateLetters(
            employmentStatus,
            combinedIssues
          );
          const templateLettersList = data.map((item) => ({
            id: item.id,
            letter: item.letter,
          }));
          setTemplateLettersList(templateLettersList);
        }
        setAdviceNote(adviceNoteParagraph);
        setStatementList(statementList);
        nextButton.disabled = false;
      } catch (e) {
        handleError(e, 'load', "Error fetching advice notes or statements");
      }
    }
    if (survey.currentPage.name === "narrative") {
      survey.getQuestionByName("statements_list").choices = ["Loading..."];
    }
    if (survey.currentPage.name === "statements" && options.isNextPage) {
      survey.setValue("statements_list", []);
      try {
        const { case_summary: summary } = sender.data || {};
        const message = `Statements: ${JSON.stringify(
          statementList
        )}\n\nSummary: ${summary}`;
        let ids = [];
        if (queryParamValue !== "response-letter") {
          const { data: { response } = {} } = await grappleAi(
            message,
            paraPrompt
          );
          ids = JSON.parse(response || "[]");
        }
        const filterQuery =
          ids.length > 0
            ? ids
                .map((id) => `&filters[id][$in]=${encodeURIComponent(id)}`)
                .join("")
            : "&filters[id][$in]=";

        const { data: { data: paragraphsData } = {} } = await getParagraphs(
          filterQuery
        );
        const statementslist = paragraphsData.map((item) => ({
          value: item.id,
          text: item.attributes.Statement,
        }));
        if (statementslist.length === 0) {
          survey
            .getQuestionByName("statements_list")
            .addError(
              "We can't give you accurate advice based on the info provided. Please go back and write a little more detail. Thanks so much!"
            );
          return;
        }
        survey.getQuestionByName("statements_list").choices = statementslist;
      } catch (error) {
        handleError(error, 'load', "Error fetching paragraphs");
      }
    }
  };

  const onCompleting = async (sender, options) => {
    options.allowComplete = false;
    const completeButton = document.querySelector(".sv-footer__complete-btn");
    if (completeButton) {
      completeButton.disabled = true;
    }
    if (queryParamValue === "response-letter") {
      const responseLetterType = survey.getValue("response_letter_type");
      const letterFrom = survey.getValue("letter_from");
      const letterTo = survey.getValue("letter_to");
      letterInfo.letterTo = letterTo;
      letterInfo.letterFrom = letterFrom;
      if (responseLetterType === "wp_letter") {
        letterInfo.PromptText = letterInfo.PromptTextWpLetter;
      } else {
        letterInfo.PromptText = letterInfo.PromptTextGrLetter;
      }
    }
    if (isUserLoggedin && authToken) {
      try {
        await updateUser(authToken, { survey: sender.data });
      } catch (e) {
        handleError(e, 'update', "Error updating user data");
      }
    }
    try {
      const {
        case_summary: summary,
        statements_list,
        comment_response,
      } = sender.data || {};
      const filterQuery =
        queryParamValue !== "response-letter"
          ? statements_list.length > 0
            ? statements_list
                .map((id) => `&filters[id][$in]=${encodeURIComponent(id)}`)
                .join("")
            : "&filters[id][$in]="
          : [];

      const { data: { data: paragraphsData } = {} } = await getParagraphs(
        filterQuery
      );
      const paragraphsText = paragraphsData
        .map((item) => item?.attributes?.Paragraph)
        .filter(Boolean)
        .join("\n\n");

      const templateLetters = templateLettersList
        .map((item, index) =>
          item?.letter
            ? `Example without prejudice letter ${index + 1}:\n${item.letter}`
            : null
        )
        .filter(Boolean)
        .join("\n\n");
      let employmentIssuesSelected = "";
      if (
        sender.data.employment_issues &&
        sender.data.employment_issues.length > 0
      ) {
        employmentIssuesSelected = employmentIssues
          .filter((employmentIssue) =>
            sender.data.employment_issues.includes(employmentIssue.value)
          )
          .map((item) => item?.label)
          .join(", ");
      }

      let discriminationIssuesSelected = "";
      if (sender.data.other_issues && sender.data.other_issues.length > 0) {
        discriminationIssuesSelected = discriminationIssues
          .filter((discriminationIssue) =>
            sender.data.other_issues.includes(discriminationIssue.value)
          )
          .map((item) => item?.label)
          .join(", ");
      }
      let employmentStatus = statusList.find(
        (status) => status.value === sender.data.employment_status
      );
      employmentStatus = employmentStatus.label;
      const jobDuration =
        sender.data.how_long === "M2y"
          ? "More than 2 years"
          : "Less than 2 years";
      let updatedSummary = `${summary}\n\n### Are you still employed: ${employmentStatus}\n\n### How long have you been in your job: ${jobDuration}`;
      if (employmentIssuesSelected) {
        updatedSummary += `\n\n### Employment issues: ${employmentIssuesSelected}`;
      }
      if (discriminationIssuesSelected) {
        updatedSummary += `\n\n### Discrimination issues: ${discriminationIssuesSelected}`;
      }
      let letterContent =
        queryParamValue === "wp-letter"
          ? `Paragraphs:\n${paragraphsText}\n\nLetters:\n${templateLetters}`
          : paragraphsText;
      navigate("/letter", {
        state: {
          lastPage: `/data-inputs?letter=${queryParamValue}&lastPage=${
            queryParamValue === "response-letter"
              ? "response-letter"
              : "questions-6"
          }`,
          isUserLoggedin: isUserLoggedin,
          letterName: queryParamValue,
          letterTitle: letterTitle,
          letterInfo: letterInfo,
          adviceNote: adviceNote,
          paragraphs: letterContent,
          userInput:
            queryParamValue === "response-letter"
              ? comment_response
              : updatedSummary,
          dataInputs: sender.data,
          email: authToken ? userData.email : "",
        },
      });
    } catch (error) {
      if (completeButton) {
        completeButton.disabled = false;
      }
      handleError(error, 'submit', "Error completing survey");
    }
  };

  return (
    <div className="user-flow">
      <div id="tooltip" className="tooltip" style={{ display: "none" }}>
        <span
          className="close-icon"
          onClick={() =>
            (document.getElementById("tooltip").style.display = "none")
          }
        >
          <img src={Close} width="12" height="12" alt="close-icon" />
        </span>
        {modalContent}
      </div>
      <div className="input-header">
        <h5 className="letter-title">{letterTitle && letterTitle}</h5>
        {/* {queryParamValue === "response-letter" && <PremiumLetterHeader />} */}
      </div>
      <Survey
        model={survey}
        onTextMarkdown={onTextMarkdown}
        onAfterRenderQuestion={onAfterRenderQuestion}
        onAfterRenderPage={onAfterRenderPage}
        onValueChanged={onValueChanged}
        onValidateQuestion={onValidateQuestion}
        onCurrentPageChanged={onCurrentPageChanged}
        onCompleting={onCompleting}
      />
      <ErrorMessage {...error} componentName="DataInputs" />
    </div>
  );
}
export default DataInputs;
