import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";

function ErrorMessage({ type = 'submit', error, description, componentName } = {}) {
  const location = useLocation();

  useEffect(() => {
    if (!error) {
      return;
    }
  
    const ex =
      error instanceof Error
        ? error
        : new Error("Application error: " + String(error));

    Sentry.captureException(ex, {
      tags: { page: location.pathname },
      extra: { description, componentName },
    });
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!error) {
    return null;
  }

  const message =
    type === 'submit'
      ? "There was an error when submitting your form"
      : "There was an error when loading the page";

  return (
    <div className="error-message-container">
      <p><strong>{message}</strong>, it is on us not on you.</p>
      <p>Please try again later or reach us directly at communications@monacosolicitors.co.uk</p>
    </div>
  );
}

export default ErrorMessage;
