import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import { AuthContext } from "../shared/AuthContext";
import { useEffect } from "react";
import { getToken } from "../shared/Helpers";
import getUser from "../app/services/getUser";

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState();

  const authToken = getToken();

  const fetchLoggedInUser = async (token) => {
    try {
      const response = await getUser(token);
      const data = response.data;
      setUserData(data);
      Sentry.setUser(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUser = (user) => {
    setUserData(user);
  };

  useEffect(() => {
    if (authToken) {
      fetchLoggedInUser(authToken);
    }
  }, [authToken]);

  return (
    <AuthContext.Provider value={{ user: userData, setUser: handleUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
