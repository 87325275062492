import * as Sentry from "@sentry/react";
import config from "./config";

const initSentryWithFailedRequestsLogging = () => {
  if (!config.SENTRY_DSN) {
    return;
  }

  const TRACE_TARGETS = [config.domain, config.BASE_URL, config.ROTA_URL, config.GRAPPLE_ASSISTANT_BASE_URL];

  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: config.ENV,
    integrations: [
      Sentry.browserTracingIntegration({ traceFetch: false, traceXHR: false }),
      // Sentry.captureConsoleIntegration({ levels: ["error"] }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    beforeSend(event, hint) { // Filter-out 'Request aborted' errors (e.g. user navigates away before request completes)
      if (hint.originalException?.message?.includes('Request aborted')) {
        return null;
      }
      return event;
    },
  });

  // Log all request errors to Sentry

  const checkSameHost = (url1, url2) => {
    const host1 = new URL(url1).host;
    const host2 = new URL(url2).host;
    return host1 === host2;
  };

  const isInTraceTargets = (url) => TRACE_TARGETS.some((target) => checkSameHost(url, target));

  // Patch fetch to log errors to Sentry
  const originalFetch = window.fetch;
  window.fetch = async (...args) => {
    try {
      const response = await originalFetch(...args);
      if (!response.ok && isInTraceTargets(args[0])) {
        const error = new Error(`Fetch failed: ${response.statusText}`);
        Sentry.captureException(error, {
          extra: {
            url: args[0],
            options: args[1],
            status: response.status,
            statusText: response.statusText,
            body: args[1]?.body,
          },
        });
      }
      return response;
    } catch (error) {
      if (isInTraceTargets(args[0])) {
        Sentry.captureException(error, {
          extra: {
            url: args[0],
            options: args[1],
            body: args[1]?.body,
          },
        });
      }
      throw error;
    }
  };

  // Patch XMLHttpRequest to log errors to Sentry
  const originalXhrOpen = XMLHttpRequest.prototype.open;
  XMLHttpRequest.prototype.open = function (method, url, ...rest) {
    this._url = url;
    this._method = method;
    return originalXhrOpen.call(this, method, url, ...rest);
  };

  const originalXhrSend = XMLHttpRequest.prototype.send;
  XMLHttpRequest.prototype.send = function (body) {
    this._body = body;
    this.addEventListener("load", function () {
      if (this.status >= 400 && isInTraceTargets(this._url)) {
        const error = new Error(`XHR failed: ${this.statusText}`);
        Sentry.captureException(error, {
          extra: {
            url: this._url,
            method: this._method,
            status: this.status,
            statusText: this.statusText,
            body: this._body,
          },
        });
      }
    });
    this.addEventListener("error", function () {
      if (isInTraceTargets(this._url)) {
        const error = new Error(`XHR network error: ${this.statusText}`);
        Sentry.captureException(error, {
          extra: {
            url: this._url,
            method: this._method,
            status: this.status,
            statusText: this.statusText,
            body: this._body,
          },
        });
      }
    });
    return originalXhrSend.apply(this, arguments);
  };

};

initSentryWithFailedRequestsLogging();
