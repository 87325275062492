import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EMPLOYEMENT_STATUS,
  EMPLOYED_FOR,
  renderSection,
  formatIssueText,
} from "../utils/Constant";
import { filterArrayByIssueType } from "../shared/Helpers";
import getIssues from "../app/services/getIssues";
import EditIcon from "../assets/img/Edit.svg";
import getWpLetter from "../app/services/getWpLetter";
import getGrievanceLetter from "../app/services/getGrievanceLetter";
import getEt1Letter from "../app/services/getEt1Letter";
import getDsarLetter from "../app/services/getDsarLetter";
import getResponseLetter from "../app/services/getResponseLetter";
import getWitnessStatementLetter from "../app/services/getWitnessLetter";
import UpdateLetterPopup from "../components/UpdateLettterPopup";

function UpdateLetter() {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [queryParamValue, setQueryParamValue] = useState("");
  const [letterText, setLetterText] = useState("");
  const [shopPopup, setShowPopup] = useState(false);
  const [issues, setIssues] = useState({
    other: [],
    employment: [],
    dismissal: [],
    redundancy: [],
  });

  const { lastPage, userData, letterData } = state || {};
  const surveyData = userData.survey;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams) {
      const paramValue = queryParams.get("letter");
      setQueryParamValue(paramValue);
      if (!paramValue) {
        navigate("/dashboard");
      }
    }
  }, [location, navigate]);

  useEffect(() => {
    let letterText = "";
    const fetchLetter = async (letterType) => {
      switch (letterType) {
        case "advice-note":
          letterText = "Advice note";
          return { text: letterText };
        case "wp-letter":
          letterText = "Without Prejudice Letter";
          return { letter: await getWpLetter(), text: letterText };
        case "grievance-letter":
          letterText = "Grievance Letter";
          return { letter: await getGrievanceLetter(), text: letterText };
        case "et1-letter":
          letterText = "ET1 Letter";
          return { letter: await getEt1Letter(), text: letterText };
        case "dsar-letter":
          letterText = "DSAR Letter";
          return { letter: await getDsarLetter(), text: letterText };
        case "response-letter":
          letterText = "Response to employer";
          return { letter: await getResponseLetter(), text: letterText };
        case "witness-statment-letter":
          letterText = "Witness Statement";
          return {
            letter: await getWitnessStatementLetter(),
            text: letterText,
          };
        default:
          return Promise.reject("Invalid letter type");
      }
    };

    const fetchData = async () => {
      try {
        if (queryParamValue) {
          const { letter, text } = await fetchLetter(queryParamValue);
          if (letter && text) {
            setLetterText(text);
          }
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    if (queryParamValue !== "advice-note") {
      fetchData();
    } else {
      setLetterText("Advice note");
    }
  }, [queryParamValue]);

  useEffect(() => {
    const fetchIssues = async () => {
      try {
        const response = await getIssues();
        const data = response.data.data;
        const issueTypes = [1, 2, 3, 4];

        const newIssues = issueTypes.reduce((acc, type) => {
          acc[type] = filterArrayByIssueType(data, type).map((issue) => ({
            value: issue.attributes.TopicId,
            text: issue.attributes.Name,
          }));
          return acc;
        }, {});

        setIssues({
          employment: newIssues[1],
          other: [{ value: "none", text: "None" }, ...newIssues[2]],
          dismissal: newIssues[3],
          redundancy: newIssues[4],
        });
      } catch (error) {
        console.error("Error fetching issues:", error);
      }
    };

    fetchIssues();
  }, []);

  const updateAnswer = () => {
    setShowPopup(true);
  };

  const onHide = () => {
    setShowPopup(false);
  };

  const back = () => {
    navigate(`${lastPage}`);
  };

  const showLetter = () => {
    navigate(`/generated-letter`, {
      state: {
        lastPage: `/dashboard`,
        letterName: queryParamValue,
        letterTitle: letterText,
        letterData: letterData,
        userData: userData,
      },
    });
  };

  return (
    <>
      <div className="update-letter">
        <div className="user-answers">
          <h2>{letterText || "Update letter"}</h2>
          <p>
            <b>Do you want to use same answers?</b>
          </p>
          <p>Either continue below or update your answers</p>
          <div className="answers-container">
            <div className="answers">
              {surveyData.date &&
                renderSection(
                  "Date of last incident",
                  surveyData.date.split("-").reverse().join("-")
                )}

              {surveyData.employment_status &&
                renderSection(
                  "Are you still employed",
                  EMPLOYEMENT_STATUS[surveyData.employment_status]
                )}

              {surveyData.how_long &&
                renderSection(
                  "How long have you been in your job?",
                  EMPLOYED_FOR[surveyData.how_long]
                )}
              {surveyData.employment_issues &&
                renderSection(
                  "Employment issues",
                  formatIssueText(
                    surveyData.employment_issues,
                    issues.employment
                  )
                )}

              {surveyData.other_issues &&
                renderSection(
                  "Other issues",
                  formatIssueText(surveyData.other_issues, issues.other)
                )}

              {surveyData.case_summary &&
                renderSection("Summary of your case", surveyData.case_summary)}

              {surveyData.comment_response &&
                renderSection(
                  "Comments about your employer's response",
                  surveyData.comment_response
                )}
            </div>
            <div
              className="action"
              onClick={updateAnswer}
              style={{ cursor: "pointer" }}
            >
              <img src={EditIcon} alt="icon" />
              Update answers
            </div>
          </div>
        </div>
        <div className="action-buttons">
          <div>
            <div
              className="button back"
              onClick={back}
              style={{ cursor: "pointer" }}
            >
              Back
            </div>
            <div
              className="button continue"
              onClick={showLetter}
              style={{ cursor: "pointer" }}
            >
              Yes, Continue
            </div>
          </div>
        </div>
      </div>
      {shopPopup && (
        <UpdateLetterPopup onHide={onHide} queryParamValue={queryParamValue} />
      )}
    </>
  );
}

export default UpdateLetter;
